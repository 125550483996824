html {
  color: #000;
  background: #FFF;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

i, em {
  font-style: italic;
}

a {
  color: #06F;
  text-decoration: none;
  outline: none;
}

a:hover {
  text-decoration: underline;
}

body {
  background: #fff;
  color: #333;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10px;
  text-align: center;
}

.header {
  margin: 20px 0 0 0;
}

a {
  color: black;
}

.nav a, .nav .nothing, .topbanner a {
  padding: 5px;
  display: inline-block;
}

.sunshine {
  max-width: 400px;
  margin-bottom: 10px;
  background: hsla(260, 50%, 50%, 1.0);
  line-height: 1.5;
  color: white;

  text-decoration: none !important;

  opacity: 0.9;
}

.sunshine:hover {
  opacity: 1;
}

.pane {
  width: 650px;
  background: #FFF;
  margin: 0px auto;
  padding: 0;
}

.comic {
  margin: 20px 0 32px;
}

@media (max-device-width: 710px) {
  body {
    font-size: 20px;
  }

  .pane {
    margin: 0px auto;
  }

  .topbanner a {
    font-weight: normal;
  }
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

h5, h5 a {
  font-family: Impact, Arial, Helvetica, sans-serif;
  font-size: 36px;
  letter-spacing: 2px;
  color: #1C9EFF;
  font-weight: 400;
}

h5 {
  margin-bottom: 10px;
}

.footer {
  text-align: center;
  color: #888;
  margin: 10px 0;
}

b, strong, .archive a, .userlink {
  font-weight: 700;
}

.header, .nav, .stupid {
  color: #999;
}

.archive {
  text-align: center;
  padding-top: 20px;
}

.archive span {
  color: #666666;
}

.archive .comic_link {
  color: #aaa;
  margin: 10px 0;
}

.about p {
  margin: 10px 0;
  text-align: center;
}